import React, { useRef, useState,useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './pdf.css';
import html2pdf from 'html2pdf.js';
import NavbarMain from './NavbarMain';

import { useNavigate } from 'react-router-dom';
const OfferLetter = React.forwardRef(({ formData = {} }, ref) => {
  
  const {
    
   
   
   
    dateOfJoining = ''
  } = formData;
  const formatDate = (dateStr) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    return date.toISOString().split('T')[0].split('-').reverse().join('-');
  };
  return (
    <div ref={ref} className="offer-letter-container">
    <div className="offer-letter">
      <div className="page">
        <div className="header">
          <div className="header-left">
            <h3 style={{ textTransform: 'uppercase', fontFamily: '' }}>MRV TECHNOLOGY</h3>
          </div>
         
          <div className="header-right">
            <img src="https://offer-offer-1.s3.ap-south-1.amazonaws.com/images1.jpg" alt="Page" />
          </div>
        </div>
        <div className="line" style={{backgroundColor:'black',height:'3px'}}></div>
        <div className="content">
          <p style={{textAlign:'center',fontSize:'23px',fontWeight:'bold'}}>PAYSLIP FOR THE MONTH OF {formData.currentMonthYear}</p>
          <table className="salary-table-pay">
            <tbody>
            
              <tr>
              <td style={{backgroundColor:'#bdbbbe',width:'175px'}}>Employee Code</td>
                <td>{formData.employeeCode}</td>
                <td style={{backgroundColor:'#bdbbbe',width:'175px'}}>Employee Name</td>
                <td>{formData.employeeName}</td>
               
              </tr>
              <tr>
               
                 <td style={{backgroundColor:'#bdbbbe'}}>Division</td>
                <td>{formData.Division}</td>
                <td style={{backgroundColor:'#bdbbbe'}}>Zone</td>
                <td>{formData.Zone}</td>
              </tr>
              <tr>
              <td style={{backgroundColor:'#bdbbbe'}}>Branch</td>
                <td>{formData.Branch}</td>
                <td style={{backgroundColor:'#bdbbbe'}}>Location</td>
                <td>{formData.Location}</td>
               
              </tr>
              <tr>
              <td style={{backgroundColor:'#bdbbbe'}}>Date of Joining</td>
                <td>{formData.dateOfJoining}</td>
                <td style={{backgroundColor:'#bdbbbe'}}>Department</td>
                <td>{formData.Department}</td>
                
              </tr>
              <tr>
              <td style={{backgroundColor:'#bdbbbe'}}>Designation</td>
                <td>{formData.Designation}</td>
                <td style={{backgroundColor:'#bdbbbe'}}>PAN No.</td>
                <td></td>
                
              </tr>
              <tr>
              <td style={{backgroundColor:'#bdbbbe'}}>PF Account No.</td>
                <td></td>
                <td style={{backgroundColor:'#bdbbbe'}}>ESIC Number</td>
                <td></td>
             
            </tr>
            <tr>
              <td style={{backgroundColor:'#bdbbbe'}}>UAN Number</td>
              <td>{formData.uan}</td>
              <td style={{backgroundColor:'#bdbbbe'}}>Bank Account No.</td>
              <td>{formData.BankAccountNo}</td>
            </tr>
            <tr>
              <td style={{backgroundColor:'#bdbbbe'}}>
              Bank Name
              </td>
              <td>{formData.BankName}</td>
               <td style={{backgroundColor:'#bdbbbe'}}>Total Working Days</td>
                <td>{formData.totalWorkingDays}</td>
            </tr>
            <tr>
            <td style={{backgroundColor:'#bdbbbe'}}>Paid Days</td>
                <td>{formData.paidDays}</td>
                <td style={{backgroundColor:'#bdbbbe'}}></td>
                <td></td>
            </tr>
            <tr><td colSpan={4} height={20}></td></tr>
            <tr>
              <td  style={{ textAlign: 'center', fontWeight: 'bold',backgroundColor:'#bdbbbe' }}>Earnings</td>
              <td  style={{ textAlign: 'center', fontWeight: 'bold',backgroundColor:'#bdbbbe' }}>Amount</td>

              <td  style={{ textAlign: 'center', fontWeight: 'bold',backgroundColor:'#bdbbbe' }}>Deductions</td>
              <td  style={{ textAlign: 'center', fontWeight: 'bold',backgroundColor:'#bdbbbe' }}>Amount</td>

            </tr>
            <tr>
              <td>Basic Salary</td>
              <td style={{textAlign:'right'}}>₹{formData.basicSalary}</td>
              <td>Provident Fund</td>
              <td style={{textAlign:'right'}}>₹{formData.epf}</td>
            </tr>
            <tr>
              <td>House Rent Allowances</td>
              <td style={{textAlign:'right'}}>₹{formData.houseRentAllowances}</td>
              <td>PT</td>
              <td style={{textAlign:'right'}}>₹{formData.professionalTax}</td>
            </tr>
            <tr>
              <td>Medical Allowances</td>
              
              <td style={{textAlign:'right'}}>₹{formData.otherAllowances}</td>
              <td>Tax Deducted at Source</td>
              <td style={{textAlign:'right'}}>₹{formData.eps}</td>
            </tr>
            <tr>
              <td>Conveyance</td>
              <td style={{textAlign:'right'}}>₹{formData.conveyance}</td>
              <td>Salary Advance</td>
              <td></td>
            </tr>
            <tr>
              <td>Professional Allowances</td>
              <td style={{textAlign:'right'}}>₹{formData.professionalAllowances}</td>
              <td>Other Deductions</td>
              <td style={{textAlign:'right'}}>₹{formData.otherdeductions}</td>
            </tr>
            <tr>
              <td className='bo' style={{backgroundColor:'#bdbbbe'}}>Gross Salary</td>
              <td className='bo' style={{textAlign:'right',backgroundColor:'#bdbbbe'}}>₹{formData.grosspay}</td>
              <td className='bo'  style={{backgroundColor:'#bdbbbe'}}>Gross Deductions</td>
              <td className='bo'  style={{textAlign:'right',backgroundColor:'#bdbbbe'}}>₹{formData.grossdeductions}</td>
            </tr>
            <tr>
              <td className='bo'  style={{backgroundColor:'#bdbbbe'}} >Net Pay</td>
              <td className='bo' style={{textAlign:'right',backgroundColor:'#bdbbbe'}}>₹{formData.netPay}</td>
              <td  style={{backgroundColor:'#bdbbbe'}} >

              </td>
              <td  style={{backgroundColor:'#bdbbbe'}} ></td>
            </tr>
           
          </tbody>
          <br></br>
          
        </table>
        <div style={{marginLeft:'60px'}}>
        <b>Net Pay:</b> ₹{formData.netPay} only 
          <br></br><br></br>
          <b>In words:</b> {formData.amountInWords}</div>
      </div>
      <div className="footer" style={{marginTop:'70px'}}>
      
<p style={{textAlign:'center'}}>This is a system generated report and does not require signature or stamp.</p>
<div className="line" style={{backgroundColor:'black',height:'2px'}}></div>
        <h1 style={{color: 'rgb(1, 118, 173)',fontFamily: 'Times New Roman, Times, serif' }}>MRV TECHNOLOGY</h1>
        <p>
        2nd Floor, West Wing, No.16, Rajiv Gandhi Salai, Karapakkam, Chennai - 600 097.<br />
        Web: www.mrvtechnology.com E-mail: info@mrvtechnology.com Ph: 93618 73224
      </p>
      </div>
    </div>
  </div>
  </div>

);
});



const Fetchpay = () => {

  const navigate= useNavigate()
 
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(7);
  const [currentRecord, setCurrentRecord] = useState({});

  const [searchQuery, setSearchQuery] = useState('');
  const offerLetterRef = useRef();
  const [shouldPrint, setShouldPrint] = useState(false);

  const storedToken=localStorage.getItem('token')
  const headers = {
    Authorization: `Bearer ${storedToken}`,
  };

  const fetchData = async () => {
    try {
      const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/fetchpay', { headers });
  
      // Check if the response is not empty
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
  
      const data = await response.json();
      if (!data || data.length === 0) {
        console.error("No data received from the server");
        return;
      }
  
    
  
      // Filter data to include only records with letterstatus as active
      const filteredData = data.filter(record => record.letterstatus === 'active');
      // Sort the filtered data by id in descending order
      setTableData(data.sort((a, b) => b.id - a.id));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  useEffect(() => {
    const checkToken = async () => {
      if (!storedToken) {
        navigate('/LoginPage');
        return;
      }

      try {
        const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/validate-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: storedToken }),
        });

        const data = await response.json();
        if (data.isValid) {
          // Token is valid, fetch the data
          fetchData();
        } else {
          localStorage.removeItem('token');
          navigate('/LoginPage');
        }
      } catch (error) {
        localStorage.removeItem('token');
        navigate('/LoginPage');
      }
    };

    checkToken();
  }, [navigate, storedToken]);

  // Logic for pagination
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = tableData.slice(indexOfFirstRecord, indexOfLastRecord);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrint = useReactToPrint({
    content: () => offerLetterRef.current,
    documentTitle: `Offer_Letter_${currentRecord?.employeeName}`,
  });
  const handleEdit = (item) => {
    localStorage.setItem('editData', JSON.stringify(item));
    navigate('/Editpayslip');
  };

  const handleDelete = async (item) => {
    if (window.confirm(`Are you sure you want to delete the record for ${item.employeeName}?`)) {
      try {
        // Example token, replace with your actual token retrieval logic
        const token = localStorage.getItem('token'); // Fetch token from localStorage or any other secure place
  
        // Use `id` instead of `employeeCode` for deletion
        const response = await fetch(`https://mrv1.indianwelfarefoundation.org.in/deletePayslip/${item.id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json', // Set content type if needed
            'Authorization': `Bearer ${token}` // Send the token in the Authorization header
          }
        });
  
        if (response.ok) {
          alert('Payslip deleted successfully');
          // Refresh the data after deletion
          fetchData(); // Ensure this function fetches the updated data
        } else {
          alert('Failed to delete record');
        }
      } catch (error) {
        console.error('Error deleting record:', error);
        alert('Error deleting record');
      }
    }
  };
  
  
  const printRecord = (record) => {
    setCurrentRecord(record);
    setShouldPrint(true);
  };

  useEffect(() => {
    if (shouldPrint) {
      handlePrint();
      setShouldPrint(false); // Reset the print trigger
    }
  }, [shouldPrint, handlePrint]);

  const filteredData = tableData.filter(record =>
    (record.employeeName?.toLowerCase() || '').includes(searchQuery?.toLowerCase() || '')
  );
  const nav = () => {
    navigate('/Payslip')
  }
  return (
    <div>
      <NavbarMain />
      <button onClick={nav} style={{ marginLeft: '20px', alignItems: 'start', marginTop: '20px', padding: '15px', textTransform: 'capitalize' }}>Generate Payslip</button>

      <center><h2>Offer Letters Generated</h2></center>
      <div className="App">
        <input
          type="text"
          placeholder="Search by name..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ float: 'right', marginRight: '20px', marginBottom: '10px' }}
        />
        <table className="styled-table" style={{ textTransform: 'capitalize', textAlign: 'center', padding: "20px" }}>
          <thead style={{ textAlign: 'center' }}>
            <tr style={{ textAlign: 'center' }} className='tb'>
              <th style={{ padding: '8px', textAlign: 'center' }}>Payslip Date</th>
              <th style={{ padding: '8px', textAlign: 'center' }}>Designation</th>
              <th style={{ padding: '8px', textAlign: 'center' }}>Employee Name</th>
              <th style={{ padding: '8px', textAlign: 'center' }}>Department</th>
              <th style={{ padding: '8px', textAlign: 'center' }} colSpan={3}>Action</th>
            </tr>
          </thead>
          <tbody>
          {filteredData.slice(indexOfFirstRecord, indexOfLastRecord).map((item, index) => (
              <tr key={index}>
                <td style={{ padding: '8px', textAlign: 'center' }}>{item.currentMonthYear}</td>
                <td style={{ padding: '8px', textAlign: 'center' }}>{item.designation}</td>
                <td style={{ padding: '8px', textAlign: 'center' }}>{item.employeeName}</td>
                <td style={{ padding: '8px', textAlign: 'center' }}>{item.department}</td>
                <td style={{ padding: '8px', textAlign: 'center' }}>
                  <button onClick={() => printRecord(item)}>Print</button>
                </td>
                <td style={{ padding: '8px', textAlign: 'center' }}>
                  <button  onClick={() => handleEdit(item)}>Edit</button>
                  </td><td style={{ padding: '8px', textAlign: 'center' }}>
                  <button  onClick={() => handleDelete(item)} style={{ marginLeft: '10px',backgroundColor:'red' }}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Pagination */}
        <div className="pagination">
          {tableData.length > recordsPerPage && (
            <ul>
              {Array(Math.ceil(filteredData.length / recordsPerPage))
                .fill()
                .map((_, index) => (
                  <li key={index} onClick={() => paginate(index + 1)} className={currentPage === index + 1 ? 'active' : ''}>
                    {index + 1}
                  </li>
                ))}
            </ul>
          )}
        </div>
        <div style={{ display: 'none' }}>
          <OfferLetter ref={offerLetterRef} formData={currentRecord} />
        </div>
      </div>
    </div>
  );
};

export default Fetchpay;